
import { fireService } from "@/service";
import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";
import { ImagePreview } from "vant";
interface UserInfo {
  department_name: string;
  name: string;
  head_pic: string;
  id: string;
  _state: number;
}
type Log = {
  event_id: string;
  id: string;
  obj_uids: string;
  obj_uids_array: UserInfo[];
  opt_content: string;
  opt_time_name: string;
  opt_type: number;
  opt_uid: string;
  opt_uid_array: UserInfo[];
  pic_ids_array: any[];
  close_reason: number;
  show_content: string;
  event_array: { id: string; event_code: string }[];
  approval_array: { id: string; code: string }[];
  workorder_array: { id: string; orderid: string }[];
};
export default defineComponent({
  name: "FireLog",
  setup() {
    const route = useRoute();

    const state = reactive({
      list: [] as Log[]
    });

    const eventsNames = {
      "1": "关闭事务",
      "2": "超级关闭",
      "3": "新建事务",
      "4": "取消报事",
      "5": "巡检数据异常",
      "6": "维保数据异常",
      "7": "指派给",
      "8": "领取事务",
      "9": "转工单",
      "10": "转审批",
      "11": "驳回关闭申请",
      "12": "通过关闭申请",
      "13": "系统自动通过关闭申请",
      "14": "将审核人修改为",
      "15": "完成评价",
      "16": "系统默认好评",
      "17": "同意指派",
      "18": "拒绝指派"
    };

    const getEventLog = async () => {
      const { data } = await fireService.fireLog(route.params.id as string);
      state.list = data.data;
    };
    getEventLog();

    const toGuaranteeDetil = (id: string) => {
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: `/pages/guaranteeDetil/main?id=${id}`
      });
    };
    return { ImagePreview, state, moment, eventsNames, toGuaranteeDetil };
  }
});
